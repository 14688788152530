@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 210 24% 16%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 173 37% 17%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 22 100% 46%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 0 0% 80%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }
}

/* @media screen and (min-width: 640px) {
  @layer base {
    h1, .h1 {
      @apply text-primary;
      font-size: 64px;
      line-height: 1.2;
    }
    h2, .h2 {
      @apply text-primary;
      font-size: 48px;
      line-height: 1.1;
    }
    h3, .h3 {
      font-size: 24px;
      line-height: 1.2;
    }
    h4, .h4 {
      font-size: 20px;
      line-height: 1.4;
    }
    h5, .h5 {
      font-size: 16px;
      line-height: 1.2;
    }
    h6, .h6 {
      font-size: 14px;
      line-height: 1.2;
    }
  }
} */

@media screen and (min-width: 768px) {
  h1,
  .h1 {
    @apply font-bold;
    font-size: 64px;
    line-height: 1.2;
  }
  h2,
  .h2 {
    @apply font-extrabold;
    font-size: 40px;
    line-height: 1.1;
  }
  h3,
  .h3 {
    @apply font-semibold;
    font-size: 28px;
    line-height: 1.2;
  }
  h4,
  .h4 {
    @apply font-semibold;
    font-size: 24px;
    line-height: 1.4;
  }
  h5,
  .h5 {
    @apply font-semibold;
    font-size: 16px;
    line-height: 1.2;
  }
  h6,
  .h6 {
    @apply font-semibold;
    font-size: 14px;
    line-height: 1.2;
  }
}

@layer base {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    @apply mb-1 font-heading text-primary;
  }
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    @apply mb-1 font-primary text-primary;
  }

  h1,
  .h1 {
    @apply font-bold;
    font-size: 48px;
    line-height: 1.2;
  }
  h2,
  .h2 {
    @apply font-extrabold;
    font-size: 28px;
    line-height: 1.1;
  }
  h3,
  .h3 {
    @apply font-semibold;
    font-size: 20px;
    line-height: 1.2;
  }
  h4,
  .h4 {
    @apply font-semibold;
    font-size: 18px;
    line-height: 1.4;
  }
  h5,
  .h5 {
    @apply font-semibold;
    font-size: 16px;
    line-height: 1.2;
  }
  h6,
  .h6 {
    @apply font-semibold;
    font-size: 14px;
    line-height: 1.2;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  body {
    @apply bg-background text-foreground;
  }
  html {
    scroll-behavior: smooth;
    @apply font-primary;
  }
  * {
    @apply border-border;
  }
  .edges {
    @apply mx-auto max-w-7xl px-6 sm:px-10 lg:px-8;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.group:hover .group-hover\:svgShadow {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
}
